import { type OpenShiftCountResponse } from "@src/appV2/OpenShifts/api/useOpenShiftCount";

import { type HcpRequirement } from "../Documents/types";

interface Props {
  openShiftCountData: OpenShiftCountResponse;
  requirement: HcpRequirement;
}

export function getPotentialShiftsAndFacilitiesForRequirement(props: Props) {
  const { openShiftCountData, requirement } = props;

  const slots = Object.values(openShiftCountData).flatMap((openShiftDay) => openShiftDay.slots);

  const facilityIdsWithRequirements = slots
    .filter((slot) => {
      return requirement?.requiredBy?.includes(slot.facilityId);
    })
    .map((slot) => {
      return slot.facilityId;
    });

  return {
    potentialShiftsCount: facilityIdsWithRequirements.length,
    potentialFacilitiesCount: new Set(facilityIdsWithRequirements).size,
  };
}
